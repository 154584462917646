<!-- 新建对话addNewChat -->
<template>
    <div class='addNewContent'>
        <div class="topNav">
          <div class="chatLogo"></div>
          <div class="introduce">
            你好，我是觉悟大模型
          </div>
          <div class="introduceMin">
            我可以理解人类的语言，希望能成为你的参谋!
          </div>
        </div>
        <div class="MidChosen">
          <div class="changeButton" @click="refresh()">
            <i class="icon iconfont lebo-rerefresh" id="refreshIcon"></i>
            <span>换一换</span>
          </div>
          <div class="cardBox">
            <choosenModelCard class="innerCard"  v-for="(item) in showBrainChoosenList[nowBrainIndex]" :key="item._id" @getModelTalk="addBrainDialog" @showTipWords="defaultWords"  :brainDetail="item"></choosenModelCard>
          </div>
        </div>
        <div class="bottomInput">
          <inputBox @addNew="addNewCommonDialog" :isAddNewDialog="true" :defaultContent="inputTipWords"></inputBox>
        </div>
    </div>
</template>

<script>
import inputBox from '../../components/inputBox/index.vue'
import choosenModelCard from '../choosenModelCard/index.vue'
import { getBrainListByTag, addNewChatRoom } from '@/api/juewuApi.js'
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
export default {
  name: 'addNewChat',
  components: { inputBox, choosenModelCard },
  props: {},
  data () {
    return {
      rotateNum: 360, // 刷新旋转
      allBrainChoosenList: [], // 全部智囊团
      showBrainChoosenList: [],
      nowBrainIndex: 0, // 当前刷新页数
      totalBrain: 0, // 总页数
      timeStap: 0,
      inputTipWords: ''// 提示词
    }
  },
  watch: {},
  computed: {
    ...mapState('juewuStore', ['allHistoryChatRoom', 'activeChatNum', 'activeBrainNum', 'allBrainChatRoom'])
  },
  methods: {
    ...mapMutations('juewuStore', ['getAllHistoryRoom', 'changeActiveNum', 'changeBrainActiveNum', 'changeNowPanlActiveName']),
    // 刷新
    refresh () {
      if (Date.now() - this.timeStap > 1000) {
        const iconDom = document.getElementById('refreshIcon')
        iconDom.style.transition = 'all 1s'
        iconDom.style.transform = 'rotate(' + this.rotateNum + 'deg)'
        this.rotateNum += 360
        if (this.nowBrainIndex < this.totalBrain) {
          this.nowBrainIndex++
        } else {
          this.nowBrainIndex = 0
        }
        this.timeStap = Date.now()
      }
    },
    // 开启智囊团新对话
    addBrainDialog (type) {
      console.log('type', type)
      console.log('this.allBrainChatRoom', this.allBrainChatRoom)
      let sameBrainIndex = ''
      let sameBrainItem = ''
      this.allBrainChatRoom.forEach((item, index) => {
        if (type._id === item.staff_id) {
          sameBrainIndex = index
          sameBrainItem = item
        }
      })
      console.log('sameBrainItem', sameBrainItem)
      if (sameBrainIndex !== '') {
        // 跳转到原有对话
        this.changeBrainActiveNum(sameBrainIndex)
        this.$router.push({
        // name: 'commoneChat',
          path: '/brain-chat?id=' + sameBrainItem._id + '&staff_id=' + sameBrainItem.staff_id
        })
      } else {
        // 新增
        this.fnAddHistoryDialog('', type._id)
      }
      this.changeNowPanlActiveName('brain')
      console.log('sameBrainIndex', sameBrainIndex)
    },
    // 开启普通大模型对话
    addNewCommonDialog (msg) {
      console.log('addnew', msg)
      if (msg.question_name.trim()) {
        this.$router.push({
          name: 'commoneChat',
          params: {
            firstMsg: msg.question_name.trim(),
            questionType: msg.question_type,
            questionData: '',
            newRoomId: msg.dialogu_id,
            firstQuestionId: msg._id,
            firstDetial: msg
          }
        })
        this.changeNowPanlActiveName('common')
      }
    },
    // 提示词
    defaultWords (words) {
      this.inputTipWords = words
    },
    // 获取全部智囊团
    async fngetBrainListByTag () {
      const res = await getBrainListByTag()
      console.log('res全部智囊团', res)
      if (res && res.Code === 200) {
        this.allBrainChoosenList = res.Data
        const filterList = this.groupListSplice([], res.Data, 6)
        console.log('filterList', filterList)
        this.showBrainChoosenList = filterList
        this.totalBrain = filterList.length - 1
      }
    },
    groupListSplice (newArr, arr, num) {
      if (arr.length > 0) {
        if (arr.length > num) {
          newArr.push(arr.splice(0, num)) // 分组
          this.groupListSplice(newArr, arr, num) // 递归
        } else {
          // 如果长度不够分为一组，就直接把剩余的分为一组
          newArr.push(arr.splice(0, arr.length))
        }
      }
      return newArr // 返回已分组的新数组
    },
    // 新增智囊团对话房间
    async fnAddHistoryDialog (msg, id) {
      const res = await addNewChatRoom({ dialogu_name: msg, staff_id: id, is_staff: true })
      console.log('新增智囊团对话', res)
      if (res && res.Code === 200) {
        this.$store.dispatch('juewuStore/fngetBrainRoomList', { is_staff: true })
        this.changeBrainActiveNum(0)
        this.$router.push({
        // name: 'commoneChat',
          path: '/brain-chat?id=' + res.Data._id + '&staff_id=' + res.Data.staff_id
        })
      }
    }
  },
  created () {},
  mounted () {
    this.fngetBrainListByTag()
  }
}
</script>

<style scoped lang="less">
.addNewContent{
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding:10px 120px 0;
  box-sizing: border-box;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  .topNav{
    width: 90%;
    height: 20%;
    // transform: translateY(10px);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-image: url('~@/assets/img/juewu/addNewBgp.png');
    background-size: 100% 100%;
    background-position: center center;
    background-repeat: no-repeat;
    .chatLogo{
      width: 70px;
      height: 70px;
      // background: #FFFFFF;
      // box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.15);
      // border-radius: 10px 10px 10px 10px;
    background-image: url('~@/assets/img/juewu/addNewLogo.png');
    background-size: 100% 100%;
    background-position: center center;
    background-repeat: no-repeat;
    }
    .introduce{
      text-align: center;
      font-size: 24px;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      color: #333333;
    }
    .introduceMin{
      font-size: 16px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #666666;
      line-height: 19px;
    }
  }
  .MidChosen{
    width: 90%;
    height:50%;
    .changeButton{
      width: 100%;
      height: 30px;
      line-height: 30px;
      padding-right: 50px;
      box-sizing: border-box;
      // background-color: yellowgreen;
      text-align: end;
      cursor: pointer;
      #refreshIcon{
        font-size: 14px;
        line-height: 14px;
        display: inline-block;
        margin-right: 8px;
      }
    }
    .cardBox{
      width: 100%;
      height: 100%;
      // padding: 10px;
      // box-sizing: border-box;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
      .innerCard{
        width: 31%;
        height: 40%;
        margin:10px;

      }
    }
  }
  .bottomInput{
    // !!!!应该需要全局定义的输入组件样式,以固定下部样式不变
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 78%;
    height:25%;
    padding-bottom: 25px !important;
    box-sizing: border-box;
  }
}
</style>
